<div class="jobdetail-article">
    <div class="container" style="padding-right: 10px">
        <div class="jobdetail-status " style="display: flex;
    flex: 1;
    flex-direction: row;">
            <div class=" fitcontent nomobile" >
                <a routerLink="/openpositions">Back</a>
                <!--img *ngIf="jobpost.isUrgent" style="width: 30px; margin-right: 5px;" src="assets/img/immediate.png" alt="Logo" class="immedidate"-->
            </div>
            <div class="fitcontent fillCon">
                <h5 style="text-align: center; color: rgb(0, 71, 123)">
                    Job for {{ jobpost.title }}
                </h5>
                <p style="text-align: center;"><span *ngIf="jobpost.openPositionsCount" style="text-align: center;color: darkgreen; ">
                    {{ jobpost.openPositionsCount }} Open Positions </span>  
                    <span *ngIf="jobpost.openPositionsCount && jobpost.isUrgent"> | </span>
                    <span *ngIf="jobpost.isUrgent" style="color:red">
                        <img *ngIf="jobpost.isUrgent" style="width: 20px; display: inline;" src="assets/img/immediate.png" alt="Logo" class="immedidate">
                        Urgent
                    </span>
                </p>
            </div>
            <div class="postedon shareOrApply" style="flex: 0.3;padding-left: 6%;">
                <div style=" display: flex; flex-direction: row; flex: 1;">
                    <div class=" fitcontent onlymobile" >
                        <a routerLink="/openpositions">Back</a>
                    </div>
                    <div style="flex: 0.5;">
                        <button class="btn btn-info " style="margin-right: -5%;" id="applyBtn " (click)="openPopup() "><i class='bx bxs-hand-up'></i> Apply </button>
                    </div>
                    <div style="flex: 0.5;    padding-left: 8%;">
                        <a class="postedon btn btn-primary linkedin-share " href="https://www.linkedin.com/sharing/share-offsite/?url={{ joburl }} " id="shareBtn " target=" _blank "> <i class='bx bxl-linkedin iconStyle'></i>
                            <i class='bx bxs-share-alt iconStyle'></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <div class="article-content container ">

        <!--div class="row justify-content-md-center">
            <div class="col-3">
                
            </div>
        </div-->

        <div class="row ">
            <div class="col ">
                <h6 style=" display: inline-block "><i class='bx bx-map' style="color: crimson;font-size: x-large; margin-right: 5px; "></i></h6><span>{{ jobpost.workLocation }}</span><br />
            </div>
            <div class="col">
                <span *ngIf="jobpost.payScale " class="payscale" style="display: inline-block; color: #7996d7;font-weight: 500; float: right;"><i *ngIf="jobpost.payScale " class='bx bx-money' style="font-size: x-large; color: darkgreen;"></i>Estimated {{ jobpost.payScale }} </span>
            </div>
        </div>
        <div class="row ">
            <div class="col ">
                <h6 style="display: inline-block "><i class='bx bxs-time' style="font-size: x-large;margin-right: 5px; color: crimson; "></i></h6><span>{{ jobpost.duration }} Months</span>
            </div>
            <div class="col " style="text-align: end; ">
                <span *ngIf="jobpost.updatedTime" style="display: inline-block; color: #7996d7;font-weight: 500; ">Posted on: {{ jobpost.updatedTime| date: 'MM/dd/yyyy' || jobpost.date }} </span>
            </div>
        </div>
        <div>
            <h6>Job Description:</h6>
            <p class="formatjobcontent " [innerHTML]="jobpost.description "></p>
        </div>
        <div *ngIf="jobpost.responsibilities ">
            <h6>Responsibilities:</h6>
            <p class="formatjobcontent " [innerHTML]="jobpost.responsibilities "></p>
        </div>
        <div *ngIf="jobpost.requirements ">
            <h6>Requirements:</h6>
            <p class="formatjobcontent " [innerHTML]="jobpost.requirements "></p>
        </div>
    </div>
</div>


<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Apply for {{ jobpost.title }} Job</h4>
                <a class="modelClose" (click)="closePopup()">X</a>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-sm-12" [ngClass]="{'successmsg': isSuccessMsg === true}">
                    <strong>{{responseMessage}}</strong>
                </div>
                <div class="contact-form" style="padding: 5px !important;">
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row ">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="firstname" id="firstname" type="text" placeholder="First Name" class="form-control ">
                                    <div *ngIf="f.firstname.touched && f.firstname.invalid" class="invalid-feedback">
                                        <div *ngIf="f.firstname.errors.required">First Name is required.</div>
                                        <div *ngIf="f.firstname.errors.minlength">First Name should be 3 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="lastname" id="lastname" type="text" placeholder="Last Name" class="form-control">
                                    <div *ngIf="f.lastname.touched && f.lastname.invalid" class="invalid-feedback">
                                        <div *ngIf="f.lastname.errors.required">Last Name is required.</div>
                                        <div *ngIf="f.lastname.errors.minlength">Last Name should be 3 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="email" id="email" type="email" placeholder="Email" class="form-control">
                                    <div *ngIf="f.email.touched && f.email.invalid" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required.</div>
                                        <div *ngIf="f.email.errors.email">Please, enter valid email address.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="phone" id="phone" type="tel" pattern="[0-9]{10}" placeholder="Phone" class="form-control">
                                    <div *ngIf="f.phone.touched && f.phone.invalid" class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">phone is required.</div>
                                        <div *ngIf="f.phone.errors.pattern">Should be 10 digit mobile number</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group form-fields">
                                    <textarea name="message" class="form-control" id="message" formControlName="message" cols="30" rows="4" [maxlength]=220 required placeholder="Tell about yourself"></textarea>
                                    <!--p style="color: gray; font-size: 10px">Please mention if you are interested in training or exam vouchers, we can send you existing offers from our site
                                    <a style="color: blue;cursor: pointer;" (click)="setTalentTreasureClicked()">Talentstreasure</a>
                                    </p-->
                                    <div *ngIf="f.message.touched && f.message.invalid" class="invalid-feedback">
                                        <div *ngIf="f.message.errors.required">Message is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group form-fields">
                                    <label for="attachment" class="col-md-4" style="margin-bottom: 0;">Upload Your Resume</label>
                                    <input name="attachment" id="attachment" type="file" accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="onUploadFile($event)" class="form-control col-md-8" style="display: inline-block;">
                                </div>
                            </div>
                            <!--div class="col-lg-12 col-md-12">
                                <div class="form-group form-fields">
                                    <input name="isUpskill" style="height: auto; width: auto; display: inline-block; margin-right: 3px;" formControlName="isUpskill" id="isUpskill" type="checkbox" class="form-control">
                                    <label for="isUpskill" style="margin-bottom: 0;">Whether you are interested to take certifications or trainings?</label>
                                    <label>Disclaimer: Answering to this question doesn't affect your job prospect?</label>
                                </div>
                                
                            </div-->
                            <div class="col-lg-12 col-md-12 text-center">
                                <button class="default-btn" id="modelSubmit">Submit</button>
                            </div>
                            
                        </div>
                    </form>
                </div>

            </div>

        </div>
    </div>
</div>