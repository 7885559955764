import { Component, OnInit } from '@angular/core';
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    // this.titleService.setTitle( `Right Talents - About Us`);
    //       this.metaService.updateTag({name: 'og:title', content: 'Right Talents - About Us'});
    //       this.metaService.updateTag({name: 'og:description', content: 'RightTalents at Right Time and Right Cost'});
    //       this.metaService.updateTag({name: 'og:url', content: 'https://righttalents.net/about'});
    //       this.metaService.updateTag({name: 'title', content: 'Right Talents - About Us'});
    //       this.metaService.updateTag({name: 'description', content: 'RightTalents at Right Time and Right Cost'});
  }

}
