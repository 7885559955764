<!-- Inner Banner -->
<div class="inner-banner inner-bg11">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Training</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Training</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Services Details -->
<div class="services-details pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-details-content">
                    <div class="service-advantage">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="advantage-item">
                                    <h3>Training</h3>
                                    <p>We facilitate/provide training to acquire new skills to find the Right job and to advance the skills for Career development.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Services Details End -->

<!-- Leader Area>
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Presence And Be A Leader Of The World</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci.</p>
            </div>
        </div>
    </div>
</div>
< Leader Area End -->

<!-- Contact Area 
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Consulting Quote</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ante nisi, feugiat vel leo eget, dictum.</p>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div-->
<!-- Contact Area End -->