<!-- Inner Banner -->
<div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Projects</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Projects</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Project Area -->
<div class="project-area pt-100 pb-70">
    <div class="container">
        <div class="project-title-two">
            <div class="section-title">
                <span>Our Projects</span>
                <h2>Some Of Our Projects Work Where We Helped</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci.</p>
            </div>
        </div>
        
        <div class="tab project-tab text-center">
            <ul class="tabs">
                <li><a href="#">Solicitory</a></li>
                <li><a href="#">Strategy</a></li>
                <li><a href="#">Human Resources</a></li>
                <li><a href="#">Business Planning</a></li>
                <li><a href="#">Project Management</a></li>
            </ul>

            <div class="tab_content current active pt-45">
                <div class="tabs_item current">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img4.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Business Planning</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img5.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Strategic Plan</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img6.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card project-card-bg">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img4.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Business Planning</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img5.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Strategic Plan</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img6.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card project-card-bg">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img4.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Business Planning</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img5.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Strategic Plan</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img6.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card project-card-bg">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img4.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Business Planning</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img5.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Strategic Plan</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img6.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card project-card-bg">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="project-tab-item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img4.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Business Planning</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img5.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Strategic Plan</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img6.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card project-card-bg">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img1.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img2.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Solicitory</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-6">
                                <div class="project-card">
                                    <a routerLink="/projects-details"><img src="assets/img/project/project-img3.jpg" alt="Images"></a>
                                    <div class="project-content project-content-bg">
                                        <h3><a routerLink="/projects-details">Start Ups</a></h3>
                                        <div class="content">
                                            <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                                            <a routerLink="/projects-details" class="project-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                                        </div>
                                        <div class="project-card-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Project Area End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Presence And Be A Leader Of The World</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci.</p>
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Consulting Quote</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ante nisi, feugiat vel leo eget, dictum.</p>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->