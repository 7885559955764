<div class="inner-banner clients-bg">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Clients</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Clients</li>
            </ul>
        </div>
    </div>
</div>
<div class="services-details pt-100 pb-70">
    <div class="container">
        <h3>Our Clients</h3><br>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6">
               <img class="stylelogo clientforresterimg" src="assets/img/client/Forrester+Research+Inc.jpg" alt="Image">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <img class="nycdoittholder" src="assets/img/client/nycdoitt.png" alt="Image">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <img class="attimg" src="assets/img/client/logo_att_share.png" alt="Image">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <img class="stylelogo clientnycssimg" src="assets/img/client/nycdeptsocialservices.png" alt="Image">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <img class="stylelogo paloaltoimg" src="assets/img/client/paloalto.png" alt="Image">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <img class="stylelogo  nycenvimg" src="assets/img/client/nycenvironmental.jpg" alt="Image">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 ">
                <img class="stylelogo  clientOptinetImg" src="assets/img/client/client_optinet.png" alt="Image">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 ">
                <img class="stylelogo   clientAugustaImg" src="assets/img/client/client_Augusta.png" alt="Image">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 ">
                <img class="stylelogo   clientfireDeptImg" src="assets/img/client/client_nyc_firedept.png" alt="Image">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 ">
                <img class="stylelogo clientPrutechImg" src="assets/img/client/client_prutech.png" alt="Image">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 ">
                <img class="stylelogo clientNycersImg" src="assets/img/client/client_nycers.png" alt="Image">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 ">
                <img class="stylelogo  clientNycdssImg" src="assets/img/client/client_NYC_DSS.png" alt="Image">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 ">
                <img class="stylelogo  clientNycdssImg" src="assets/img/client/districtattorney.jpg" alt="Image">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 ">
                <img class="stylelogo  clientNycdssImg" src="assets/img/client/nyhousingauthority.png" alt="Image">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6 ">
                <img class="stylelogo  clientNycdssImg" src="assets/img/client/image008.png" alt="Image">
            </div>
            <div class="col-lg-2 col-md-2 col-sm-3">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <img class="stylelogo nycitsimg " src="assets/img/client/nyc_its.png" alt="Image">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-6">
                <img class="stylelogo nycitsimg " src="assets/img/client/client_paradigm.png" alt="Image">
            </div>
            <div class="col-lg-2 col-md-2 col-sm-3">
            </div>
        </div>
    </div>
</div>