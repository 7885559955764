<!-- Inner Banner -->
<div class="inner-banner inner-bg9">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Style One</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Services Style One</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Services Area -->
<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="service-widget-title">
            <div class="section-title text-center">
                <span>Our Services</span>
                <h2>Our Best Consulting Services Make You To Reach Your Goal</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit . Pellentesque lacinia vitae libero eu suscipit. Morbi porttitor vel risus at urna semper Suspendisse sodales urna erat, suscipit consequat nisl egestas nec.</p>
            </div>
        </div>

        <div class="row pt-45 pb-20">
            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-balance"></i></a>
                    <h3><a routerLink="/services-details">Solicitory</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-sort"></i></a>
                    <h3><a routerLink="/services-details">Business Planning</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-analytics"></i></a>
                    <h3><a routerLink="/services-details">Project Managment</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-chess-pieces"></i></a>
                    <h3><a routerLink="/services-details">Strategy</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-briefcase-1"></i></a>
                    <h3><a routerLink="/services-details">Business Consulting</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-people"></i></a>
                    <h3><a routerLink="/services-details">Human Resources</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/services-1" class="page-numbers">2</a>
                    <a routerLink="/services-1" class="page-numbers">3</a>
                    <a routerLink="/services-1" class="next page-numbers">Next <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Services Area End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Presence And Be A Leader Of The World</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci.</p>
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Consulting Quote</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ante nisi, feugiat vel leo eget, dictum.</p>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->