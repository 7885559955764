<!-- Inner Banner -->
<div class="inner-banner contactus-bg">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Contact Section -->
<div class="contact-section  pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="contact-card row">
                    <i class='bx bxs-phone col-sm-3'></i>
                    <div class="content col-sm-9 col-md-9 col-lg-12">
                        <h3>Contact Number</h3>
                        <p>Ph&nbsp;&nbsp;:<a style="display:inline-block" href="tel:+1-973-320-8610"> +1-973-320-8610</a><br/> Fax:
                            <a style="display: inline-block" href="tel:+1-862-772-0446"> +1-862-772-0446</a></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="contact-card row">
                    <i class='bx bx-map col-sm-3'></i>
                    <div class="content col-sm-9 col-md-12">
                        <h3>Address</h3>
                        <p>639 Passaic Ave STE C, Nutley</p>
                        <span>NJ 07110</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12 col-md-6">
                <div class="contact-card row">
                    <i class='bx bx-envelope col-sm-3'></i>
                    <div class="content col-sm-9 col-md-12">
                        <h3>Email</h3>
                        <p>Mail Id: <a href="mailto:info@righttalents.net">info@righttalents.net</a></p>
                        <!--p>Job Seekers: <a href="mailto:resumes@righttalents.net">resumes@righttalents.net</a></p>
                        <p>Employers: <a href="mailto:requirements@righttalents.net">requirements@righttalents.net</a></p>
                        <p>Training: <a href="mailto:Training@righttalents.net">Training@righttalents.net</a></p>
                        <p>Consulting: <a href="mailto:Consulting@righttalents.net">Consulting@righttalents.net</a></p>
                        <p>Open Positions: <a href="mailto:resumes@righttalents.net">resumes@righttalents.net</a></p-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Section End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Presence And Be A Leader Of The World</h2>
                <p> We value the human capital as an indispensable asset to any organization and we strive to acquire the best of the best talents by offering top of the industry level compensation, benefits and career progression by training and development.</p>
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Contact Us</h2>
                        </div>
                        <form [formGroup]="form" (ngSubmit)="submit()">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group form-fields">
                                        <input formControlName="firstname" id="firstname" type="text" placeholder="First Name" class="form-control">
                                        <div *ngIf="f.firstname.touched && f.firstname.invalid" class="invalid-feedback">
                                            <div *ngIf="f.firstname.errors.required">First Name is required.</div>
                                            <div *ngIf="f.firstname.errors.minlength">First Name atleast be 3 character.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group form-fields">
                                        <input formControlName="lastname" id="lastname" type="text" placeholder="Last Name" class="form-control">
                                        <div *ngIf="f.lastname.touched && f.lastname.invalid" class="invalid-feedback">
                                            <div *ngIf="f.lastname.errors.required">Last Name is required.</div>
                                            <div *ngIf="f.lastname.errors.minlength">Last Name should be 3 character.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group form-fields">
                                        <input formControlName="email" id="email" type="email" placeholder="Email" class="form-control">
                                        <div *ngIf="f.email.touched && f.email.invalid" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email is required.</div>
                                            <div *ngIf="f.email.errors.email">Invalid email address</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group form-fields">
                                        <input formControlName="phone" id="phone" type="tel" pattern="[0-9]{10}" placeholder="Phone" class="form-control">
                                        <div *ngIf="f.phone.touched && f.phone.invalid" class="invalid-feedback">
                                            <div *ngIf="f.phone.errors.required">phone is required.</div>
                                            <div *ngIf="f.phone.errors.pattern">Should be 10 digit mobile number</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group form-fields">
                                        <textarea name="message" class="form-control" id="message" formControlName="message" cols="30" rows="8" maxlength="254" required placeholder="Message"></textarea>
                                        <div *ngIf="f.message.touched && f.message.invalid" class="invalid-feedback">
                                            <div *ngIf="f.message.errors.required">Message is required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="file" accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document" name="attachment" id="attachment" (change)="onUploadFile($event)" class="form-control" placeholder="Attachment">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 text-center">
                                    <button class="default-btn">
                                            Submit
                                        </button>
                                </div>

                                <div class="col-lg-12 col-sm-12" [ngClass]="{'successmsg': isSuccessMsg === true}">
                                    <strong>{{responseMessage}}</strong>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->

<!-- Map Area -->
<div class="map-area">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3019.055106497081!2d-74.14917404915045!3d40.82675323842294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2ff5d5bfdbac7%3A0xd7f4841c297bc97a!2s639%20Passaic%20Ave%20STE%20C%2C%20Nutley%2C%20NJ%2007110%2C%20USA!5e0!3m2!1sen!2sin!4v1612600669193!5m2!1sen!2sin"
        width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
</div>
<!-- Map Area End -->