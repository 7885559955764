<!-- Inner Banner -->
<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Case Study</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Case Study</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Case Area -->
<div class="case-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Case Study</span>
            <h2>What Type of Work We Have Done</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="case-card">
                    <a routerLink="/projects-details"><img src="assets/img/case-study/case-study1.jpg" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/projects-details">Complete Analysis</a></h3>
                        <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                        <a routerLink="/projects-details" class="case-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-card">
                    <a routerLink="/projects-details"><img src="assets/img/case-study/case-study2.jpg" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/projects-details">Aqua Elinath</a></h3>
                        <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                        <a routerLink="/projects-details" class="case-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-card">
                    <a routerLink="/projects-details"><img src="assets/img/case-study/case-study3.jpg" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/projects-details"> Business Planning</a></h3>
                        <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                        <a routerLink="/projects-details" class="case-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-card">
                    <a routerLink="/projects-details"><img src="assets/img/case-study/case-study4.jpg" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/projects-details">Business Consulting</a></h3>
                        <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                        <a routerLink="/projects-details" class="case-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-card">
                    <a routerLink="/projects-details"><img src="assets/img/case-study/case-study5.jpg" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/projects-details">Human Resources</a></h3>
                        <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                        <a routerLink="/projects-details" class="case-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-card">
                    <a routerLink="/projects-details"><img src="assets/img/case-study/case-study6.jpg" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/projects-details">Project Management</a></h3>
                        <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                        <a routerLink="/projects-details" class="case-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/case-study" class="page-numbers">2</a>
                    <a routerLink="/case-study" class="page-numbers">3</a>
                    <a routerLink="/case-study" class="next page-numbers">Next <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Case Area End -->