<div class="unsubscribebg pt-100 pb-70">
    <div class="container">
        <div class="row">
            <h3 class="col-lg-12 col-md-12 col-sm-12" *ngIf="type==='unsubscribe'">We will stop the campaiging mails to your below mail ids from now onwards!! Please click on the submit</h3>
            <h3 class="col-lg-12 col-md-12 col-sm-12" *ngIf="type==='subscribe'">We are happy to have you in our subscribers list! We will post you regular feeds from our site</h3>
            <br/>
            <form id="suborunsubform" role="form" class="col-lg-12 col-md-12 col-sm-12" (ngSubmit)="onSubmit()" [formGroup]="suborunsubform">
                <div>
                    <input type="email" name="semail" id="semail" formControlName="emailId" class="col-lg-4 col-md-4 col-sm-4 form-control" required placeholder="Email"/>
                </div>
                <br/>
                <div>
                    <input type="submit" class="default-btn">
                </div>
                <br/>
                <div style="font-size: 20px; color: #185c37">
                    {{resp}}
                </div>
            </form>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        <div>
    </div>
</div>