<!-- Banner Area -->
<div class="banner-area-three banner-bg">
    <div class="container">
        <div class="banner-content banner-content-three">
            <span>Since 2002</span>
            <h1>Right Resource @ Right Cost and Right Time</h1>
            <p>We are a customer centric company 

                Provide Right Resources (RR) @ Right Cost (RC) & Right Time (RT).
                
                We offer top of the industry level compensation, benefits and career progression by training and development</p>
            <div class="banner-btn">
                <a routerLink="/contact" class="contact-btn">Contact Us</a>
                <!--a routerLink="/" class="get-btn">Get A Quote</a>
            </div>
        </div>
    </div>
</div>
<!-- Banner Area End -->

<!-- Status Area -->
<div class="status-area">
    <div class="container status-bg">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="status-card">
                    <h3>Complete Analysis</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet lectus nec metus tempor mattis.</p>
                    <i class='flaticon-magnifying-glass'></i>
                    <div class="status-bottom-1"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="status-card">
                    <h3>Save Your Tax</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet lectus nec metus tempor mattis.</p>
                    <i class='flaticon-bank'></i>
                    <div class="status-bottom-2"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="status-card">
                    <h3>Well Approach</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet lectus nec metus tempor mattis.</p>
                    <i class='flaticon-settings'></i>
                    <div class="status-bottom-3"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Status Area End -->

<!-- Services Area -->
<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="section-title">
                    <span>Our Services</span>
                    <h2>Our Best Consulting Services Make You To Reach Your Goal</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="service-text">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit . Pellentesque lacinia vitae libero eu suscipit.  Morbi porttitor vel risus at urna semper Suspendisse sodales urna erat, suscipit consequat nisl egestas nec.</p>
                </div>
            </div>
        </div>

        <div class="row pb-20">
            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-balance"></i></a>
                    <h3><a routerLink="/services-details">Solicitory</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-sort"></i></a>
                    <h3><a routerLink="/services-details">Business Planning</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-analytics"></i></a>
                    <h3><a routerLink="/services-details">Project Managment</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-chess-pieces"></i></a>
                    <h3><a routerLink="/services-details">Strategy</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-briefcase-1"></i></a>
                    <h3><a routerLink="/services-details">Business Consulting</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a routerLink="/services-details" class="services-icon"><i class="flaticon-people"></i></a>
                    <h3><a routerLink="/services-details">Human Resources</a></h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    <a routerLink="/services-details" class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>
        </div>

        <div class="service-view-btn text-center">
            <a routerLink="/services-1" class="view-btn">View More</a>
        </div>
    </div>

    <div class="service-shape">
        <div class="shape1"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div>
</div>
<!-- Services Area End -->

<!-- Video Area -->
<div class="video-area video-bg2">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="video-btn">
                    <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=11s" class="video-play-btn popup-btn"><i class="flaticon-play-button"></i></a>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="video-content">
                    <div class="section-title">
                        <span>Intro Video</span>
                        <h2>Explore Us Watch This Full Video and Know Us</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid mr-0 pr-0">
        <div class="guidelines-area guidelines-max guidelines-bg2">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="guidelines-content">
                        <h2>A Proper Guideline Can Make Your Business Successful</h2>
                        <p>Proin magna felis, tincidunt eget lectus id, maximus ultricies eros. Aliquam eu lacus vitae nulla faucibus mollis. Proin velit mi, molestie commodo neque laoreet a.Fusce cursus iaculis elit vitae auctor. Mauris venenatis vestibulum eros, sed turpis molestie ac. Nunc convallis nisi in maximus tincidunt.</p>
                        <div class="signature"><img src="assets/img/signature.png" alt="Images"></div>
                        <div class="content">
                            <h3>Smithy Karnes</h3>
                            <span>Founder & CEO</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Video Area End -->

<!-- About Area -->
<div class="about-area pt-100 pb-70" id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 pl-0">
                <div class="about-img-3">
                    <img src="assets/img/about/about-img5.jpg" alt="Images">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content about-content-max">
                    <span>About Us</span>
                    <h2>We Are Standing With <b>30+</b> Years Of Experience</h2>
                    <h3>Trusted And Effective Business Consulting Service Provider </h3>
                    <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis ullamcorper. Ut volutpat dignissim arcu sit amet finibus. Aenean pulvinar ornare erat. Donec a massa sed ipsum fringilla rhoncus.</p>
                    <p>Dignissim arcu sit amet finibus.Aenean pulvinar ornare erat. Donec a massa sed ipsum fringilla rhoncus.</p>

                    <div class="row">
                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>1200+</h3>
                                    <span>Award Winner</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-team"></i>
                                <div class="content">
                                    <h3>30+</h3>
                                    <span>Years Experience</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-briefcase"></i>
                                <div class="content">
                                    <h3>5000+</h3>
                                    <span>Business Guide</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="about-btn">
                        <a routerLink="/about" class="learn-btn">Learn More</a>
                        <!--a routerLink="/contact" class="get-btn">Get A Quote</a-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Area End -->

<!-- Guidelines Area -->
<div class="guidelines-area-two">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12 pl-0">
                <div class="guidelines-img">
                    <img src="assets/img/guidelines-img.jpg" alt="Images">
                </div>
            </div>

            <div class="col-lg-7 col-md-12 pr-0">
                <div class="guidelines-content-bg">
                    <div class="guidelines-content-two">
                        <h2>A Proper Guideline Can Make Your Business Successful</h2>
                        <p>Proin magna felis, tincidunt eget lectus id, maximus ultricies eros. Aliquam eu lacus vitae nulla faucibus mollis. Proin velit mi, molestie commodo neque laoreet a.Fusce cursus iaculis elit vitae auctor. Mauris venenatis vestibulum eros, sed turpis molestie ac. Nunc convallis nisi in maximus tincidunt.</p>
                        <div class="signature"><img src="assets/img/signature2.png" alt="Images"></div>
                        <div class="content">
                            <h3>Smithy Karnes</h3>
                            <span>Founder & CEO</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Guidelines Area End -->

<!-- Choose Area -->
<div class="choose-area-two pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12 pl-0">
                <div class="choose-content choose-content-bg">
                    <div class="choose-max">
                        <div class="section-title">
                            <span>Why Choose Us</span>
                            <h2>Trusted Consulting Company To Give You Right Guideline</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce rhoncus mauris ac urna finibus pellentesque. Sed pellentesque augue sit amet mauris porta suscipit. Donec condimentum efficitur neque. Integer iaculis faucibus quam vel pharetra In tempor mauris a magna ultrices dignissim. </p>
                            <a routerLink="/contact" class="default-btn">Book A Service</a>
                        </div>

                        <div class="row border-top">
                            <div class="col-lg-6 col-sm-6">
                                <div class="choose-content-list choose-content-list-color">
                                    <i class="flaticon-sort"></i>
                                    <div class="content">
                                        <h3>Fast Service</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in mi mauris.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="choose-content-list choose-content-list-color">
                                    <i class="flaticon-briefcase"></i>
                                    <div class="content">
                                        <h3>Time Saving</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in mi mauris.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="choose-content-list choose-content-list-color">
                                    <i class="flaticon-analytics"></i>
                                    <div class="content">
                                        <h3>Business Planning</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in mi mauris.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="choose-content-list choose-content-list-color">
                                    <i class="flaticon-chess-pieces"></i>
                                    <div class="content">
                                        <h3>Experienced Team</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in mi mauris.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12 pr-0">
                <div class="choose-img-3">
                    <img src="assets/img/choose/choose-img5.jpg" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

<!-- Client Area -->
<div class="client-area-two ptb-100">
    <div class="container">
        <div class="section-title text-center">
            <span>Clients Review</span>
            <h2>Our Lovely Clients Get Helped From Our Company</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla et amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae pellentesque.</p>
        </div>

        <div class="client-slider owl-carousel owl-theme pt-45">
            <div class="client-card">
                <h3>Thomas Smith</h3>
                <span>CEO Of LTD Company</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non ullamcorper tortor, vel mattis mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <div class="client-card-img">
                    <img src="assets/img/client/client-img1.png" alt="Images">
                </div>
            </div>

            <div class="client-card">
                <h3>Sinthy Alina</h3>
                <span>CEO Of Gloz</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non ullamcorper tortor, vel mattis mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <div class="client-card-img">
                    <img src="assets/img/client/client-img4.png" alt="Images">
                </div>
            </div>

            <div class="client-card">
                <h3>James Nilson</h3>
                <span>Manager Of Gloz</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non ullamcorper tortor, vel mattis mauris. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <div class="client-card-img">
                    <img src="assets/img/client/client-img3.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Client Area End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Presence And Be A Leader Of The World</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci.</p>
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Consulting Quote</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ante nisi, feugiat vel leo eget, dictum.</p>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->