<!-- Footer Area -->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/rtlogotransparent.png" alt="Images"></a>
                    </div>
                    <p>RightTalents believes in diversity for inclusive, sustainable growth and development.</p>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                        <li><a href="https://twitter.com/RighttalentsR" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                        <li><a href="https://www.linkedin.com/company/righttalents-llc/" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="footer-widget">
                    <h3>Useful Links</h3>

                    <ul class="footer-list">
                        <li><a class="usefullink" routerLink="/itstaffing-services">IT Staffing Services</a></li>
                        <li><a class="usefullink" routerLink="/itconsulting-services">IT Consulting Services</a></li>
                        <li><a class="usefullink" routerLink="/ittraining-services">IT Training Services</a></li>
                        <li><a class="usefullink" routerLink="/clients">Clients</a></li>
                        <li><a class="usefullink" routerLink="/openpositions">Open Positions</a></li>
                        <li><a class="usefullink" routerLink="/about">About Us</a></li>
                        <li><a class="usefullink" routerLink="/contact">Contact Us</a></li>
                        <li><a class="usefullink" routerLink="/subscribe" [queryParams]="{type:'subscribe'}">Subscribe Us</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Contacts</h3>

                    <ul class="footer-list-two">
                        <li>
                            <i class='bx bx-time'></i>
                            Mon - Fri: 9.00am - 6.00pm
                        </li>
                        <li>
                            <i class='bx bx-location-plus'></i>
                            639 Passaic Avenue STE C, NUTLEY, NJ - 07110 
                        </li>
                        <li>
                            <i class='bx bx-phone'></i>
                            <a href="tel:+19733208610">+1-973-320-8610</a>
                        </li>
                        <li>
                            <i class='bx bxs-envelope'></i>
                            <a href="mailto:info@righttalents.net">info@righttalents.net</a>
                        </li>
                    </ul>
                </div>
            </div>

            <!--div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-2">
                    <h3>Contacts</h3>

                    <div class="newsletter-area">
                        <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Your Email*" name="EMAIL">
                            <!--button class="default-btn" type="submit">Subscribe  </button>
                        </form>
                    </div>
                </div>
            </div-->
        </div>
    </div>
</footer>
<!-- Footer Area End -->

<!-- Copy-Right Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-text text-center">
            <p>Copyright @2024 RighTalents. All Rights Reserved by RightTalents LLC | 
                <span style="color: whitesmoke">Developed by 
                    <a style="color: whitesmoke" href="https://devgrovestechnologies.vercel.app/">devGroves Technologies</a>
                </span>
            </p>
        </div>
    </div>
</div>
<!-- Copy-Right Area End -->