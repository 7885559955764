import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'itstaffing-services-details',
  templateUrl: './itstaffing-services.component.html',
  styleUrls: ['./itstaffing-services.component.scss']
})
export class ItstaffingServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
