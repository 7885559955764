import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  type: string;
  emailId: string;
  resp: string;
  

  suborunsubform = this.fb.group({
    emailId: [],
  });

  constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      console.log(params);
      this.type = params.type || 'subscribe';
      this.emailId = params.emailId;
      this.suborunsubform.patchValue({
        emailId: params.emailId,
      });
    });
  }

  onSubmit(): void {
    console.log('on submit of the form');
    const data = {
      'type': this.type,
      'emailId': this.suborunsubform.get(['emailId']).value,
    };
    const url = 'https://righttalents.net/api/contacts/subscriptions';
    fetch(url,
      {
       method: 'PUT',
       headers: {
         'Origin': 'https://righttalents.net',
         'Accept': 'text/html',
         'Content-Type': 'application/json'
         },
        body: JSON.stringify(data)
      })
      .then((res: any) => {console.log(res.body); return res.body;})
      .then(rb => {
        const reader = rb.getReader();
      
        return new ReadableStream({
          start(controller) {
            // The following function handles each data chunk
            function push() {
              // "done" is a Boolean and value a "Uint8Array"
              reader.read().then( ({done, value}) => {
                // If there is no more data to read
                if (done) {
                  console.log('done', done, value);
                  controller.close();
                  return;
                }
                // Get the data and send it to the browser via the controller
                controller.enqueue(value);
                // Check chunks by logging to the console
                console.log('finally..', done, value);
                push();
              })
            }
      
            push();
          }
        });
      })
      .then(stream => {
        // Respond with our stream
        return new Response(stream, { headers: { "Content-Type": "text/html" } }).text();
      })
      .then(result => {
        // Do things with result
        console.log(result);
        this.resp = result;
      })
       .catch((error: any) => {console.log(error)});
  }
}
