<!-- Inner Banner -->
<div class="inner-banner consulting-bg">
    <div class="container">
        <div class="inner-title text-center">
            <h3>IT Consulting Services</h3>
            <ul>
                <!--li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li-->
                <li>We focus in IT innovations and latest business trends to help our clients for business improvement</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Services Details -->
<div class="services-details pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-details-content">
                    <div class="service-advantage">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="advantage-item">
                                    <h3>IT Consulting Services</h3>
                                    <p>At RightTalents, we remain focused in IT innovations and latest business trends to help our client organizations 
                                        leverage leading edge technologies for business improvement and maximizing returns on their IT Investment(ROI). 
                                        We strongly believe that our work is not accomplished until we deliver the solution that makes our clients happy.
                                        We specialize in enterprise-wide strategies, design, develop and implement projects within time and under budget. 
                                        Our strength lies in our determination to excel and our out of the box thinking to deliver the most challenging solution.
                                    </p>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Digital Transformation</li>
                                        <li><i class='bx bx-check'></i> Data Services</li>
                                        <li><i class='bx bx-check'></i> Cloud Services</li>
                                        <li><i class='bx bx-check'></i> Security Services</li>
                                        <li><i class='bx bx-check'></i> Project Mangaement</li>
                                        <li><i class='bx bx-check'></i> Quality Assurance/Testing</li>
                                        <li><i class='bx bx-check'></i> Web Development</li>
                                        <li><i class='bx bx-check'></i> Application Development</li>
                                        <li><i class='bx bx-check'></i> Infrastructure Management/Support</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="service-content">
                                    <h3>Have You Any Question or Query</h3>
                                    <a href="/righttalents#contactarea">Contact Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Services Details End -->
