<!-- Inner Banner -->
<div class="inner-banner employers-bg">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Employers</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Employers</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Services Details -->
<div class="services-details pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-details-content">
                    <div class="service-advantage">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="advantage-item">
                                    <h3>Employers</h3>
                                    <p>RightTalents provides high-quality professionals for contract, contract-to-hire, and permanent/fulltime positions.
                                        We provide Consulting and Training services as well.
                                        For your staffing, consulting and Training needs please <a href="/righttalents#contactarea">Contact Us</a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- Services Details End -->
