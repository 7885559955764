<!-- Inner Banner -->
<div class="inner-banner inner-bg8">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Our Team</h3>
            <ul>
                <li>
                    <a routerLink="/">Home</a>
                </li>
                <li>
                    <i class='bx bxs-chevron-right'></i>
                </li>
                <li>Team</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Team Area -->
<div class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Team</span>
            <h2>Our Best Expert Team Make You To Reach Your Goal</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur interdum nulla vel quam consectetur, ac lobortis tortor mattis. Duis a sodales felis. Orci varius natoque penatibus.</p>
        </div>

        <div class="row pt-45 pb-20">
            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/team-img1.jpg" alt="Images">

                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>John Doe</h3>
                        <span>Founder - CEO</span>
                        <p>Lorem ipsum dolor sit consectetur adipiscing elit lacinia pharetra purus.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/team-img2.jpg" alt="Images">
                        
                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Jenifar Ambrina</h3>
                        <span>Co Founder</span>
                        <p>Lorem ipsum dolor sit consectetur adipiscing elit lacinia pharetra purus.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/team-img3.jpg" alt="Images">
                        
                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Johan Smith</h3>
                        <span>Manager</span>
                        <p>Lorem ipsum dolor sit consectetur adipiscing elit lacinia pharetra purus.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/team-img4.jpg" alt="Images">
                        
                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Sendy Alina</h3>
                        <span>Team Leader</span>
                        <p>Lorem ipsum dolor sit consectetur adipiscing elit lacinia pharetra purus.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/team-img5.jpg" alt="Images">
                        
                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Fren John</h3>
                        <span>Expert</span>
                        <p>Lorem ipsum dolor sit consectetur adipiscing elit lacinia pharetra purus.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/team-img6.jpg" alt="Images">
                        
                        <div class="social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                            </ul>
                        </div>
                    </div>

                    <div class="content">
                        <h3>Henry Linda</h3>
                        <span>Solicitory Consultent</span>
                        <p>Lorem ipsum dolor sit consectetur adipiscing elit lacinia pharetra purus.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Team Area End -->