<!-- Inner Banner -->
<div class="inner-banner inner-bg11">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Service Details</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Service Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Services Details -->
<div class="services-details pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-details-content">
                    <img src="assets/img/services/services-details.jpg" alt="Images">

                    <div class="row border-bottom">
                        <div class="col-lg-6 col-md-12">
                            <div class="services-details-card">
                                <h3>The Requirements</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in sem mollis, bibendum tortor sit amet, volutpat eros. Nullam nec odio ac erat fermentum congue.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="services-details-card">
                                <h3>Project Management</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in sem mollis, bibendum tortor sit amet, volutpat eros. Nullam nec odio ac erat fermentum congue.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="services-details-card">
                                <h3>Service System  and Quality</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in sem mollis, bibendum tortor sit amet, volutpat eros. Nullam nec odio ac erat fermentum congue.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="services-details-card">
                                <h3>Our Full Service System</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in sem mollis, bibendum tortor sit amet, volutpat eros. Nullam nec odio ac erat fermentum congue.</p>
                            </div>
                        </div>
                    </div>

                    <div class="services-item-area">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="services-details-item">
                                    <i class="flaticon-balance"></i>
                                    <h3>Market Investment</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in sem mollis</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="services-details-item">
                                    <i class="flaticon-analytics"></i>
                                    <h3>Project Managment</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in sem mollis</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="services-details-item">
                                    <i class="flaticon-chess-pieces"></i>
                                    <h3>Strategy</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in sem mollis</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="services-details-item">
                                    <i class="flaticon-people"></i>
                                    <h3>Human Resources</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in sem mollis</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="service-advantage">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="advantage-item">
                                    <h3>Service Advantage</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in sem mollis, bibendum tortor sit amet, volutpat eros. Nullam nec odio ac erat fermentum congue. Sed in sem mollis.</p>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Building a Strategic Development</li>
                                        <li><i class='bx bx-check'></i> Take Advantage of any Investment</li>
                                        <li><i class='bx bx-check'></i> Plan a Investment for Future</li>
                                    </ul>
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="service-content">
                                    <h3>Have You Any Question or Querry</h3>
                                    <h2>Get Free Services</h2>
                                    <!--a routerLink="/services-1" class="default-btn">More Services</a-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-side-bar">
                    <div class="services-bar-widget">
                        <h3 class="title">Categories</h3>
                        <div class="services-bar-categories">
                            <ul>
                                <li>
                                    <i class="flaticon-sort"></i>
                                    <a href="#">Business Planning </a>
                                </li>
                                <li>
                                    <i class="flaticon-balance"></i>
                                    <a href="#">Solicitor</a>
                                </li>
                                <li>
                                    <i class="flaticon-analytics"></i>
                                    <a href="#">Project Management</a>
                                </li>
                                <li>
                                    <i class="flaticon-people"></i>
                                    <a href="#">Human Resources </a>
                                </li>
                                <li>
                                    <i class="flaticon-briefcase-1"></i>
                                    <a href="#">Business Consulting </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="services-bar-widget">
                        <h3 class="title">Tags</h3>
                        <ul class="services-bar-widget-tag">
                            <li><a href="#">Business</a></li>
                            <li><a href="#">Planning</a></li>
                            <li><a href="#">Project</a></li>
                            <li><a href="#">Managment</a></li>
                            <li><a href="#">Strategy</a></li>
                            <li><a href="#">Resources</a></li>
                        </ul>
                    </div>

                    <div class="services-bar-widget">
                        <div class="services-bar-contact">
                            <i class='bx bx-phone-call'></i>
                            <h3>Want to Know Call Us for Info</h3>
                            <span><a href="tel:(+123)-456-876">(+123)-456-876</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Services Details End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Presence And Be A Leader Of The World</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla amet bibendum ante. Vestibulum ante ipsum primis in faucibus orci.</p>
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Consulting Quote</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ante nisi, feugiat vel leo eget, dictum.</p>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->