import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder,ReactiveFormsModule} from '@angular/forms';
import { fromEvent, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { NgRecaptcha3Service } from 'ng-recaptcha3';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
  msgAttachement;
  msgattachmentContentType;
  user:any;
  responseMessage:any;
  isSuccessMsg: boolean;
  isUnsupporttedFileFormat: boolean;
  constructor(private http: HttpClient, private router: Router, private recaptcha3: NgRecaptcha3Service) {}

  ngOnInit(): void {
    let data = this.router.url.split("#").pop();
    let elem = document.getElementById(data);
    this.responseMessage="";
    this.recaptcha3.init(environment.siteKey);
    if (elem) elem.scrollIntoView();
  }
  
  ngAfterViewChecked():any{
    const element =<HTMLElement>document.getElementsByClassName('grecaptcha-badge')[0];
    element.style.zIndex = '-1';
  }
    contactForm = new FormGroup({
    firstname: new FormControl('', [Validators.required, Validators.minLength(3)]),
    lastname: new FormControl('', [Validators.required, Validators.minLength(3)]),
    phone: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}')]),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', Validators.required)
  });
  
  get f(){
    return this.contactForm.controls;
  }
  
  onSubmit(){   
        if (this.contactForm.invalid) {
          this.responseMessage = "Please fill in the required information to connect with you back..";
          this.resetResponseMessage();
            return;
        }else if(this.isUnsupporttedFileFormat){
          this.responseMessage = "Unsupported file format please upload the pdf / doc / docx..";
          this.resetResponseMessage();
            return;
        }                
        this.user = this.contactForm.value;
        let objectBody = {
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            mailId: this.user.email,
            phoneNo: this.user.phone,
            message: this.user.message,
            msgattachment: this.msgAttachement?this.msgAttachement:'',
            msgattachmentContentType: this.msgattachmentContentType?this.msgattachmentContentType:''
        } 
        this.recaptcha3.getToken().then(
        (token) => {
          const headers = { 
            'recaptchaToken':token};               
          this.http.post<any>('https://righttalents.net/api/contactuses', objectBody, { headers }).subscribe(data => {
            if(data.id !== null && data.id !== undefined && data.id !== ''){
              this.responseMessage = "Thanks! We will get back to you soon!";
              this.isSuccessMsg = true;
            }else{
              this.responseMessage = "Internal Error! Please try again...";
              this.isSuccessMsg = false;
            }
            this.resetResponseMessage();
          }); 
        },
        (error) => {
          this.responseMessage = "Internal Error! Please try again...";
          this.resetResponseMessage();
          console.error(error);
          return;
        }); 
  }
resetResponseMessage(){
  setTimeout(()=>{
    this.responseMessage="";
    // this.isUnsupporttedFileFormat = false;
  }, 8000);
}
  onUploadFile(event) {
    if (event.target.files.length > 0) {
      this.isUnsupporttedFileFormat = false;
      const fileReader = new FileReader();
      let fileToUpload = event.target.files.item(0);
      this.msgattachmentContentType = fileToUpload.type;
      const lastIndex = fileToUpload.name.lastIndexOf('.');
      const fileType = fileToUpload.name.slice(lastIndex + 1);  
      if(fileType !== "pdf" && fileType !== "doc" && fileType !== "docx"){
        this.isUnsupporttedFileFormat = true;
        this.isSuccessMsg = false;
      }
      this.fileToBase64(fileReader, fileToUpload)
        .subscribe(base64file => {
          this.msgAttachement = base64file.split('base64,')[1].trim();
        });
    }
  }

  fileToBase64(fileReader: FileReader, fileToRead: File): Observable<string> {
    fileReader.readAsDataURL(fileToRead);
    return fromEvent(fileReader, 'load').pipe(pluck('currentTarget', 'result'));
  }


}
