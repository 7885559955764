<!-- Inner Banner -->
<div class="inner-banner inner-bg10">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Style Two</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Services Style Two</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Service Area -->
<div class="service-area-two pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Services</span>
            <h2>Our Best Consulting Services Make You To Reach Your Goal</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce rhoncus mauris ac urna finibus pellentesque. Sed pellentesque augue sit amet mauris porta suscipit.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="service-item">
                    <div class="images">
                        <a routerLink="/services-details"><img src="assets/img/services/services-item1.jpg" alt="Images"></a>
                        <a routerLink="/services-2" class="service-item-icon"><i class="flaticon-balance"></i></a>
                        <a routerLink="/services-details" class="service-item-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/services-details">Solicitory</a></h3>
                        <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-item">
                    <div class="images">
                        <a routerLink="/services-details"><img src="assets/img/services/services-item2.jpg" alt="Images"></a>
                        <a routerLink="/services-2" class="service-item-icon"><i class="flaticon-analytics"></i></a>
                        <a routerLink="/services-details" class="service-item-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                    
                    <div class="content">
                        <h3><a routerLink="/services-details">Project Management</a></h3>
                        <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-item">
                    <div class="images">
                        <a routerLink="/services-details"><img src="assets/img/services/services-item3.jpg" alt="Images"></a>
                        <a routerLink="/services-2" class="service-item-icon"><i class="flaticon-sort"></i></a>
                        <a routerLink="/services-details" class="service-item-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                    
                    <div class="content">
                        <h3><a routerLink="/services-details">Business Planning</a></h3>
                        <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-item">
                    <div class="images">
                        <a routerLink="/services-details"><img src="assets/img/services/services-item4.jpg" alt="Images"></a>
                        <a routerLink="/services-2" class="service-item-icon"><i class="flaticon-people"></i></a>
                        <a routerLink="/services-details" class="service-item-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                    
                    <div class="content">
                        <h3><a routerLink="/services-details">Human Resources</a></h3>
                        <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-item">
                    <div class="images">
                        <a routerLink="/services-details"><img src="assets/img/services/services-item5.jpg" alt="Images"></a>
                        <a routerLink="/services-2" class="service-item-icon"><i class="flaticon-briefcase-1"></i></a>
                        <a routerLink="/services-details" class="service-item-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                    
                    <div class="content">
                        <h3><a routerLink="/services-details">Business Consulting</a></h3>
                        <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-item">
                    <div class="images">
                        <a routerLink="/services-details"><img src="assets/img/services/services-item5.jpg" alt="Images"></a>
                        <a routerLink="/services-2" class="service-item-icon"><i class="flaticon-chess-pieces"></i></a>
                        <a routerLink="/services-details" class="service-item-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    </div>
                    
                    <div class="content">
                        <h3><a routerLink="/services-details">Strategy</a></h3>
                        <p>Sed vel risus a sem cursus fermentum. Aliquam a fermentum velit. Sed varius nisl libero, at tempor lectus mollis.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/services-2" class="page-numbers">2</a>
                    <a routerLink="/services-2" class="page-numbers">3</a>
                    <a routerLink="/services-2" class="next page-numbers">Next <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Service Area End -->