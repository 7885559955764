<!-- Inner Banner -->
<div class="inner-banner jobseekers-bg">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Job Seekers</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>JobSeekers</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Services Details -->
<div class="services-details pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-details-content">
                    <div class="service-advantage">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="advantage-item">
                                    <h3>Job Seekers</h3>
                                    <p>Being with RightTalents is all about growth, learning, success and unlimited possibilities. At RightTalents, we believe that our people are our brand ambassadors. RightTalents believes in offering opportunity that is
                                        best suited for an individual keeping in mind their aspiration, skill sets and experience.</p>
                                    <p>Our dedication for building business and relationships has earned us the trust of our clients and our people. If you'd like to work for a rapidly growing, dynamic organization that fosters excellence and personal growth
                                        then we are looking for you and <a class="applyBtn" (click)="openPopup()">Click here</a> to submit your resume.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>
<!-- Services Details End -->

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Submit Your Resume</h4>
                <a class="modelClose" (click)="closePopup()">X</a>
            </div>
            <div class="modal-body">
                <div class="contact-form" style="padding: 5px !important;">
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row ">
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="firstname" id="firstname" type="text" placeholder="First Name" class="form-control ">
                                    <div *ngIf="f.firstname.touched && f.firstname.invalid" class="invalid-feedback">
                                        <div *ngIf="f.firstname.errors.required">First Name is required.</div>
                                        <div *ngIf="f.firstname.errors.minlength">First Name should be 3 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="lastname" id="lastname" type="text" placeholder="Last Name" class="form-control">
                                    <div *ngIf="f.lastname.touched && f.lastname.invalid" class="invalid-feedback">
                                        <div *ngIf="f.lastname.errors.required">Last Name is required.</div>
                                        <div *ngIf="f.lastname.errors.minlength">Last Name should be 3 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="email" id="email" type="email" placeholder="Email" class="form-control">
                                    <div *ngIf="f.email.touched && f.email.invalid" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required.</div>
                                        <div *ngIf="f.email.errors.email">Please, enter valid email address.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="form-group form-fields">
                                    <input formControlName="phone" id="phone" type="tel" pattern="[0-9]{10}" placeholder="Phone" class="form-control">
                                    <div *ngIf="f.phone.touched && f.phone.invalid" class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">phone is required.</div>
                                        <div *ngIf="f.phone.errors.pattern">Should be 10 digit mobile number</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group form-fields">
                                    <textarea name="message" class="form-control" id="message" formControlName="message" cols="30" rows="8" [maxlength]=255 required placeholder="Tell about yourself"></textarea>
                                    <div *ngIf="f.message.touched && f.message.invalid" class="invalid-feedback">
                                        <div *ngIf="f.message.errors.required">Message is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group form-fields">
                                    <label for="attachment" style="margin-bottom: 0;">Upload Your Resume</label>
                                    <input name="attachment" id="attachment" type="file" accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document" (change)="onUploadFile($event)" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 text-center">
                                <button class="default-btn" id="modelSubmit">Submit</button>
                            </div>
                            <div class="col-lg-12 col-sm-12" [ngClass]="{'successmsg': isSuccessMsg === true}">
                                <strong>{{responseMessage}}</strong>
                            </div>
                        </div>
                    </form>
                </div>

            </div>

        </div>
    </div>
</div>