<!-- Inner Banner -->
<div class="inner-banner inner-bg4">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Blog Details</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Blog Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Blog Details Area -->
<div class="blog-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-article">
                    <div class="blog-article-img">
                        <img src="assets/img/blog/blog-details1.jpg" alt="Images">
                    </div>

                    <div class="blog-status">
                        <div class="row">
                            <div class="col-lg-7 col-sm-7">
                                <ul>
                                    <li>JOBID0001</li>
                                    <li>2nd March 2021</li>
                                </ul>
                            </div>

                            <!--div class="col-lg-5 col-sm-5">
                                <div class="blog-comment">
                                    <h3><i class='bx bxs-message-detail'></i> 32 Comments</h3> 
                                </div>
                            </div-->
                        </div>
                    </div>

                    <div class="article-content">
                        <h2>Mobile Specialist</h2>

                        <h6>Job Description</h6>
                        <p>The Mobile Specialist serves in many capacities, including Developer, Engineer, Technical Architect,
                            and Analyst for Mobile Development, Security, and/or Infrastructure Projects. The Mobile Specialist
                            participates in project planning and in the creation and review of technical deliverables. The Mobile
                            Specialist often guides and mentors the technical team in all phases of the SDLC including
                            requirement validation, detail design, development, and implementation.
                            </p>
                        
                        <h6>Responsibilities</h6>
                        <pre style="font-family: monserrat">
    • Serves as the Designer, Developer and/or Engineer on Mobile Projects.
    • Meets with end users and technical staff of all types to gather business and
      system requirements
    • May work with Mobile Specialist 2 to propose comprehensive solutions based on 
      business and technical requirements
    • Develops and/or engineers user interface, service tier, data tier components, 
      infrastructure, or security for Mobile Projects.
    • Develops standards, repeatable processes, and reusable components
    • Assists in project planning, including developing of timelines, composition of 
      technical teams, and leveling of resources.
    • Mentors technical team leads and team members on Department processes and standards
      to promote consistency and improve productivity.
    • Assists in the development and review of technical deliverables on projects.
                        </pre>
                        <h6>Requirements</h6>
                        <pre style="font-family: monserrat">
    Bachelor’s degree in Computer Science		
    8 years overall programming experience.		
    5 years’ experience in mobile development.		
    5 years strong proficiency in JavaScript, including DOM manipulation and the 
     JavaScript object model.
    Minimum of 3+ years’ experience in mobility design and development of large-scale 
     enterprise mobile applications.
    3 years’ experience in React Native mobile applications (Android/ iOS) and native 
     Android applications.
    3 years’ experience with popular React Native workflows.
    3 years’ experience creating, optimizing, and integrating Application Programming
     Interface (API) calls.
    5 years familiarity with native build tools, like XCode, Gradle, Android Studio,
     IntelliJ.		
    5 years’ experience with automated build pipelines (Jenkins) and Git for source
     control.
    3 years excellent knowledge and capability to use OS frameworks for multi-threading,
     data persistence, and adapting user interface across multiple screen size.
    Able to work in a team environment is required.
    Previous PennDOT Mobile Team experience is highly desired.
                        </pre>
                        <blockquote class="blockquote"> 
                            <p>Awesome dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitationaco laboris nisi ut aliquip commodo consequat. </p>
                        </blockquote>
                    </div>

                    <div class="another-content">
                        <div class="content-img">
                            <div class="row">
                                <div class="col-6 col-md-12">
                                    <img src="assets/img/blog/blog-details2.jpg" alt="Images">
                                </div>

                                <div class="col-6 col-md-12">
                                    <img src="assets/img/blog/blog-details3.jpg" alt="Images">
                                </div>
                            </div>
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.</p>
                        <p>consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.</p>
                    </div>

                    <div class="blog-article-share">
                        <ul class="social-icon">
                            <li>Share This Post</li>
                            <li><a target="_blank" href="#"><i class="bx bxl-facebook"></i></a></li>
                            <li><a target="_blank" href="https://twitter.com/RighttalentsR"><i class="bx bxl-twitter"></i></a></li>
                            <li><a target="_blank" href="#"><i class="bx bxl-instagram"></i></a></li>
                            <li><a target="_blank" href="#"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a target="_blank" href="#"><i class="bx bxl-youtube"></i></a></li>
                        </ul>
                    </div>

                    <div class="comments-wrap">
                        <h3 class="title">Job Posts</h3>

                        <ul class="row">
                            <li class="col-lg-6 col-md-6 col-sm-12">
                                <i class="flaticon-briefcase"></i>
                                <p class="jobid">JOBID0001</p>
                                <h2>Mobile Specialist</h2>
                                <span>Location: Harrisburg, PA |</span>
                                <span>Duration: 6/30/2021 |</span>
                                <span>2nd Mar,2021</span>
                                <p>The Mobile Specialist serves in many capacities, including Developer, Engineer, Technical Architect, and Analyst for Mobile Development, Security, and/or Infrastructure Projects.</p>
                            </li>
                            <li class="col-lg-6 col-md-6 col-sm-12">
                                <i class="flaticon-briefcase"></i>
                                <p class="jobid">JOBID0001</p>
                                <h2>Mobile Specialist</h2>
                                <span>Location: Harrisburg, PA |</span>
                                <span>Duration: 6/30/2021 |</span>
                                <span>2nd Mar,2021</span>
                                <p>The Mobile Specialist serves in many capacities, including Developer, Engineer, Technical Architect, and Analyst for Mobile Development, Security, and/or Infrastructure Projects.</p>
                            </li>
                            <li class="col-lg-6 col-md-6 col-sm-12">
                                <i class="flaticon-briefcase"></i>
                                <p class="jobid">JOBID0001</p>
                                <h2>Mobile Specialist</h2>
                                <span>Location: Harrisburg, PA |</span>
                                <span>Duration: 6/30/2021 |</span>
                                <span>2nd Mar,2021</span>
                                <p>The Mobile Specialist serves in many capacities, including Developer, Engineer, Technical Architect, and Analyst for Mobile Development, Security, and/or Infrastructure Projects.</p>
                            </li>
                            <li class="col-lg-6 col-md-6 col-sm-12">
                                <i class="flaticon-briefcase"></i>
                                <p class="jobid">JOBID0001</p>
                                <h2>Mobile Specialist</h2>
                                <span>Location: Harrisburg, PA |</span>
                                <span>Duration: 6/30/2021 |</span>
                                <span>2nd Mar,2021</span>
                                <p>The Mobile Specialist serves in many capacities, including Developer, Engineer, Technical Architect, and Analyst for Mobile Development, Security, and/or Infrastructure Projects.</p>
                            </li>
                        </ul>
                    </div>

                    <div class="comments-form">
                        <div class="contact-form">
                            <div class="section-title">
                                <h2>Leave a comment</h2>
                            </div>

                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" placeholder="Your Message"></textarea>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn">Post A Comment</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
</div>
<!-- Blog Details Area End -->